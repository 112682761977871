@import '/src/css/general.css';

/********************** Summer Camp 2024 *********************/

/** Purple to Hot Pink **/

.hero-button-2 {
  background: rgb(94,70,191);
  background: linear-gradient(90deg, rgba(94,70,191,1) 54%, rgba(59,47,107,1) 100%);
}

.hero-button-2:hover {
  background: rgb(182,80,178);
  background: linear-gradient(90deg, rgba(182,80,178,1) 54%, rgba(112,19,109,1) 100%);
}

.live-oak-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px green;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.spwfg-img {
  min-height: 70px;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.confetti-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
    
#confetti-button {
  height: 45px;
  width: 280px;
  text-align: center;
  background: linear-gradient(90deg, rgba(94,70,191,1) 54%, rgba(59,47,107,1) 100%);
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 80px;
}

.button-emoji {
  font-size: 15px;
}