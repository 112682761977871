@import '/src/css/general.css';

/********************** Summer Camp 2024 *********************/

.hero-button-3 {
  font-weight: bold;
}

/** Hot Pink to Turquoise **/

.hero-button-3 {
  background: rgb(182,80,178);
  background: linear-gradient(90deg, rgba(182,80,178,1) 54%, rgba(112,19,109,1) 100%);
}

.hero-button-3:hover {
  background-image: linear-gradient(to right top, #1382df, #007cce, #0076be, #006fad, #06689c);
}

.pink-logo {
  position: absolute;
  z-index: 1;
  margin-top: 200px;
  margin-left: 390px;
}

.blue-logo {
  position: absolute;
  z-index: 1;
  margin-top: 790px;
  margin-left: 600px;
}
  
a.polaroid {
  display: block;
  text-decoration: none;
  color: #800080;
  padding: 10px 10px 20px 10px;
  width: 150px;
  border: 1px solid #BFBFBF;
  background-color: white;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  -webkit-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
  -moz-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
  box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
  -webkit-transition: -webkit-transform 0.5s ease-in;
  font-family: "Comic Sans MS";
}

a.polaroid-logo {
  display: block;
  text-decoration: none;
  width: 140px;
  -webkit-transition: -webkit-transform 0.5s ease-in;
}

a.polaroid:hover,
a.polaroid:focus,
a.polaroid:active {
  border-color: #6A6A6A;
  -webkit-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
  -moz-box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
  box-shadow: 2px 2px 4px rgba(0,0, 0, 0.3);
}

.polaroid img {
  margin: 0 0 15px;
  width: 150px;
  height: 150px;
  z-index: 9999;
}
  
a img {
  border: none;
  display: block;
}

.photo-album .small {
  width: 125px;
  padding: 13px 13px 26px 13px;
}

.photo-album .small img {
  width: 125px;
  height: 125px;
}
  
.photo-album .medium {
  width: 200px;
  padding: 13px 13px 26px 13px;
}

.photo-album .medium img {
  width: 200px;
  height: 200px;
}

.photo-album .medium-wide {
  width: 330px;
  padding: 13px 13px 26px 13px;
}

.photo-album .medium-wide img {
  width: 330px;
  height: 200px;
}

.photo-album .large {
  width: 300px;
  padding: 20px 20px 30px 20px;
}

.photo-album .large img {
  width: 300px;
  height: 300px;
}

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 0px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-top: 113px;
    margin-left: 20px;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    transform: rotate(2deg);
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-top: 0px;
    margin-left: 40px;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

/** More classes **/

  .photo-album .img4 {
    margin-top: 403px;
    margin-left: 160px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 80px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-top: 370px;
    margin-left: 115px;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 480px;
    margin-left: 65px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 75px;
    margin-left: 10px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 280px;
    margin-left: 15px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 20px;
    margin-left: 70px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** Smiles **/

  .photo-album .img11 {
    margin-top: 350px;
    margin-left: 0px;
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }

/** More websites **/

  .photo-album .img12 {
    margin-top: 0px;
    margin-left: 0px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** More joy **/

  .photo-album .img13 {
    margin-top: 400px;
    margin-left: 65px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }

/*************************************************** MEDIA QUERIES: MIN WIDTH 1025px to 1699.98px ******************************************/

@media (min-width: 1025px) and (max-width: 1699.98px) {

.pink-logo {
  position: absolute;
  z-index: 1;
  margin-top: 200px;
  margin-left: 390px;
}

.blue-logo {
  position: absolute;
  z-index: 1;
  margin-top: 790px;
  margin-left: 600px;
}
  
.photo-album .medium-wide {
  width: 330px;
  padding: 13px 13px 26px 13px;
}

.photo-album .medium-wide img {
  width: 330px;
  height: 200px;
}

.photo-album .large {
  width: 300px;
  padding: 20px 20px 30px 20px;
}

.photo-album .large img {
  width: 300px;
  height: 300px;
}

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 20px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-top: 113px;
    margin-left: 20px;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    transform: rotate(2deg);
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-top: 0px;
    margin-left: 40px;
    -webkit-transform: rotate(-15deg);
    -moz-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

/** More classes **/

  .photo-album .img4 {
    margin-top: 403px;
    margin-left: 160px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 80px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-top: 370px;
    margin-left: 115px;
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 480px;
    margin-left: 65px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 75px;
    margin-left: 10px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 280px;
    margin-left: 55px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 20px;
    margin-left: 70px;
    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

/** Smiles **/

  .photo-album .img11 {
    margin-top: 350px;
    margin-left: 0px;
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }

/** More websites **/

  .photo-album .img12 {
    margin-top: 0px;
    margin-left: 40px;
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    transform: rotate(5deg);
  }

/** More joy **/

  .photo-album .img13 {
    margin-top: 400px;
    margin-left: 65px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }

}

/*************************************************** MEDIA QUERIES: MIN WIDTH 768px to 1024.98px ******************************************/

@media (min-width: 768px) and (max-width: 1024.98px) {

/** Polaroid Photos **/

.pink-logo {
  margin-top: 190px;
  margin-left: 340px;
}

.blue-logo {
  margin-top: 800px;
  margin-left: 80px;
}
  
/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 0px;
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-left: 0px;
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-left: 0px;
  }

/** More classes **/

  .photo-album .img4 {
    margin-left: 120px;
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 20px;
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-left: 50px;
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 470px;
    margin-left: 0px;
  }

}

/************************************************ MEDIA QUERIES: MIN WIDTH 400px to MAX WIDTH 767.98px - Phones *************************************/

@media (min-width: 540px) and (max-width: 767.98px) {

/** Polaroid Photos **/

.pink-logo {
  margin-top: 530px;
  margin-left: 60px;
}

.blue-logo {
  margin-top: 1290px;
  margin-left: 40px;
}

.photo-album .medium-wide-1 {
  max-width: 335px;
}

.photo-album .medium-wide-1 img {
  width: 310px;
}

.photo-album .medium-wide-2 {
  max-width: 320px;
}

.photo-album .medium-wide-2 img {
  width: 295px;
}

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 30px;
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-left: 100px;
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-left: 0px;
  }

/** More classes **/

  .photo-album .img4 {
    margin-left: 90px;
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 110px;
    -webkit-transform: rotate(-7deg);
    -moz-transform: rotate(-7deg);
    transform: rotate(-7deg);
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-top: 375px;
    margin-left: 110px;
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 475px;
    margin-left: 25px;
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 0px;
    margin-left: 40px;
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 230px;
    margin-left: 100px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 0px;
  }

/** Smiles **/

  .photo-album .img11 {
    margin-left: 40px;
  }

/** More websites **/

  .photo-album .img12 {
    margin-left: 30px;
  }

/** More joy **/

  .photo-album .img13 {
    margin-left: 150px;
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    transform: rotate(8deg);
  }

}

/************************************************ MEDIA QUERIES: MIN WIDTH 400px to MAX WIDTH 539.98px - Phones *************************************/

@media (min-width: 400px) and (max-width: 539.98px) {

/** Polaroid Photos **/

.pink-logo {
  margin-top: 530px;
  margin-left: 0px;
}

.blue-logo {
  margin-top: 1290px;
  margin-left: 15px;
}

.photo-album .medium-wide-1 {
  max-width: 315px;
}

.photo-album .medium-wide-1 img {
  width: 290px;
}

.photo-album .medium-wide-2 {
  max-width: 300px;
}

.photo-album .medium-wide-2 img {
  width: 275px;
}

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 0px;
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-left: 20px;
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-left: 0px;
  }

/** More classes **/

  .photo-album .img4 {
    margin-left: 90px;
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 0px;
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-left: 85px;
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 475px;
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 0px;
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 230px;
    margin-left: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 0px;
  }

}

/*************************************************** MEDIA QUERIES: MIN WIDTH 360px to 399.98px ******************************************/

@media (min-width: 360px) and (max-width: 399.98px) {

/** Polaroid Photos **/

  .pink-logo {
    margin-left: 0px;
    margin-top: 565px;
  }

  .blue-logo {
    margin-left: 5px;
    margin-top: 1290px;
  }

  a.polaroid-logo {
    width: 120px;
  }

/** Medium wide 1 **/

  .photo-album .medium-wide-1 {
    max-width: 290px;
  }

  .photo-album .medium-wide-1 img {
    max-width: 265px;
    height: 180px;
  }

/** Medium wide 2 **/

  .photo-album .medium-wide-2 {
    max-width: 270px;
  }

  .photo-album .medium-wide-2 img {
    max-width: 245px;
    height: 180px;
  }

/** Large **/

  .photo-album .large {
    width: 280px;
  }

  .photo-album .large img {
    width: 280px;
    height: 280px;
  }

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 0px;
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-left: 10px;
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-left: 0px;
    -webkit-transform: rotate(-20deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-20deg);
  }

/** More classes **/

  .photo-album .img4 {
    margin-left: 50px;
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 0px;
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-left: 85px;
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 460px;
    margin-left: 5px;
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 0px;
    margin-left: 0px;
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 230px;
    margin-left: 0px;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    transform: rotate(2deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 0px;
    margin-left: 55px;
  }

/** More websites **/

  .photo-album .img12 {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

/** More joy **/

  .photo-album .img13 {
    margin-left: 50px;
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    transform: rotate(8deg);
  }

}


/*************************************************** MEDIA QUERIES: MIN WIDTH 320px to 359.98px ******************************************/

@media (min-width: 320px) and (max-width: 359.98px) {

/** Polaroid Photos **/

  .pink-logo {
    margin-left: 0px;
    margin-top: 565px;
  }

  .blue-logo {
    margin-left: 0px;
    margin-top: 1290px;
  }

  a.polaroid-logo {
    width: 120px;
  }

/** Medium wide 1 **/

  .photo-album .medium-wide {
    max-width: 275px;
  }

  .photo-album .medium-wide img {
    max-width: 250px;
    height: 180px;
  }

/** Medium wide 2 **/

  .photo-album .medium-wide-2 {
    max-width: 255px;
  }

  .photo-album .medium-wide-2 img {
    max-width: 230px;
    height: 180px;
  }

/** Large **/

  .photo-album .large {
    width: 280px;
  }

  .photo-album .large img {
    width: 280px;
    height: 280px;
  }

/** Large 2 **/

  .photo-album .large-2 {
    width: 270px;
  }

  .photo-album .large-2 img {
    width: 270px;
    height: 270px;
  }

/** Lesli's class **/

  .photo-album .img1 {
    margin-left: 0px;
  }

/** More girls & boys **/

  .photo-album .img2 {
    margin-left: 10px;
  }

/** Carleysue's class **/

  .photo-album .img3 {
    margin-left: 0px;
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

/** More classes **/

  .photo-album .img4 {
    margin-left: 40px;
  }

/** More instructors **/

  .photo-album .img5 {
    margin-left: 0px;
  }

/** Zoe teaching **/

  .photo-album .img6 {
    margin-left: 45px;
  }

/** Payton teaching **/

  .photo-album .img7 {
    margin-top: 470px;
    margin-left: 5px;
  }

/** Kristie guest speaker **/

  .photo-album .img8 {
    margin-top: 0px;
    margin-left: 0px;
    -webkit-transform: rotate(7deg);
    -moz-transform: rotate(7deg);
    transform: rotate(7deg);
  }

/** More coding **/

  .photo-album .img9 {
    margin-top: 230px;
    margin-left: 0px;
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    transform: rotate(2deg);
  }

/** More computers **/

  .photo-album .img10 {
    margin-top: 0px;
    margin-left: 40px;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }

/** More websites **/

  .photo-album .img12 {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }

/** More joy **/

  .photo-album .img13 {
    margin-left: 10px;
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    transform: rotate(8deg);
  }

}

