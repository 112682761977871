@import '/src/css/general.css';

.live-oak-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px green;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

/*.fs2024-photo {
  max-width: 300px;
  max-height: 300px;
}*/

/********************** Spring 2024 Saturday Sessions - Pac Man Banner *********************/

  /********************* Solid Banner & White Beads *********************/

    .banner-background {
      position: relative;
        width: 100%;
        height: 100px;
        background-color: var(--gcs-hot-pink);
        padding: 20px;
        box-sizing: border-box;       
        overflow: hidden;
    }
    
    .banner-background::after {
        content: "";
        position: absolute;
        display: block;
        border-top: 12px dotted white;    
        color: white;
        width: 90%;
        top: 50%;
        left: 5%;
        margin-top: -6px;
     }

  /********************* Chaser (PacMan) *********************/
  
     .chaser {
        position: absolute;
        z-index: 99;
        top: 50%;
        left: -20%;
      
        animation: chaserMoveForward 5s linear infinite;
        animation-delay: 0.8s;
     }

     .chaser::before, .chaser::after {
        content: "";
        display: block;
        position: absolute;
        border: solid 30px yellow;
        margin-top: -30px;
        border-radius: 50%;
        border-right-color: transparent;
        border-bottom-color: transparent;
      
        animation: mouthChompTop 0.7s ease infinite;
      }

     .chaser::after {
        border-color: yellow;
        border-right-color: transparent;
        border-top-color: transparent;
      
        animation: mouthChompBottom 0.7s ease infinite;
      }
      
      /********* Chaser (PacMan) Move Forward *********/

         @keyframes chaserMoveForward {
           0% {
             left: -20%;
           }
           100% {
             left: 100%;
           }
         }
       
      /********* Chaser (PacMan) Mouth Move Top *********/

         @keyframes mouthChompTop {
           50% {
             transform: rotate(44deg);
           }
         }

      /********* Chaser (PacMan) Mouth Move Bottom *********/

         @keyframes mouthChompBottom {
           50% {
             transform: rotate(-44deg);
           }
         }
       
  /********************* Runner (Ghost) *********************/

      .runner {
        position: absolute;
        z-index: 99;
        top: 50%;
        left: 20%;
        margin-top: -35px;
        background-color: #1A84B8;
        height: 70px;
        width: 60px;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
      
        animation: runnerMoveForward 5s linear infinite, clothesChangeColors 0.5s linear infinite;
      }
      
        /********* Runner (Ghost) Move Forward *********/

           @keyframes runnerMoveForward {
             0% {
               left: -20%;
             }
             100% {
               left: 100%;
             }
           }

      /********* Runner (Ghost) Eyes (White Circles) *********/

        .runner::before, .runner::after {
             content: "";
             position: absolute;
             background: white;
             height: 25px;
             width: 20px;
             border-radius: 100%;
             top: 15px;
             left: 4px;
           }

         .runner::after {
             left: 28px;
         }
       
      /********* Runner (Ghost) Eyes (Blue Dots) *********/

         .eyes::before, .eyes::after {
           content: "";
           position: absolute;
           background: blue;
           height: 10px;
           width: 10px;
           border-radius: 100%;
           top: 25px;
           left: 5px;
           z-index: 99;
         
           animation: runnerLookLeft 0.7s linear infinite;
         }

         .eyes::after {
           left: 30px;
        
           animation: runnerLookRight 0.7s linear infinite;
         }
       
      
        /********* Runner (Ghost) Eyes Move Left *********/

           @keyframes runnerLookLeft {
             0%, 100% {
               left: 5px;
             }
             50% {
               left: 10px;
             }
           }

        /********* Runner (Ghost) Eyes Move Right *********/ 

           @keyframes runnerLookRight {
             0%, 100% {
               left: 30px;
             }
             50% {
               left: 38px;
             }
           }
       
      /********* Runner (Ghost) Clothes *********/

         .clothes {
           position: absolute;
           border: solid 10px var(--gcs-hot-pink);
           border-top-color: transparent;
           bottom: -10px;
         }
       
           .clothes::after, .clothes::before {
             content: "";
             position: absolute;
             border: solid 10px var(--gcs-hot-pink);
             border-top-color: transparent;
             bottom: -10px;
             left: 10px;
           }
         
           .clothes::after {
             border-color: var(--gcs-hot-pink);
             border-top-color: transparent;
             bottom: -10px;
             left: 30px;
           }
        
        
       /********* Runner (Ghost) Clothes Change Colors *********/ 

         @keyframes clothesChangeColors {
             50% {
               background-color: #1A94B8;
             }
         }

/************************************************ MEDIA QUERIES: MIN WIDTH 301px to MAX WIDTH 767.98px - Phones *************************************/

@media (min-width: 301px) and (max-width: 767.98px) {

  /********* Chaser (PacMan) Move Forward *********/

  @keyframes chaserMoveForward {
        
    0% {
        left: -50%;
      }
        
    100% {
          left: 100%;
        }
    }

}

/***************************************************** MEDIA: MAX WIDTH 300px - Small Phones *******************************************/

@media (max-width: 300px) {

  /** Chaser (PacMan) Move Forward **/

    @keyframes chaserMoveForward {
      0% {
        left: -60%;
      }

      100% {
        left: 100%;
      }
    }

}