@import '../css/general.css';

#encouraging-words-modal {
  z-index: 200;
}

/*****************  TOP NAVIGATION *************************/

/* NavBar Bottom Border */

.navbar {
  border-bottom: 5px solid var(--gcs-hot-pink);
  z-index: 9999;
}

/* NavBar GCS Wordmark */

.navbar-wordmark {
  max-width: 350px;
}

.nav-campclass {
  max-width: 150px;
}

.nav-websites {
  max-width: 160px;
}

/* NavBar Links */

.nav-link {
  color: var(--gcs-hot-pink) !important;
}

.nav-link.active {
  text-decoration: underline var(--gcs-purple) wavy;
  -webkit-text-decoration: underline var(--gcs-purple) wavy;
  text-underline-offset: 3px; /** move wavy line below all letters **/
  -webkit-text-underline-offset: 3px; /** move wavy line below all letters **/
}

.nav-link-auth {
  color: #ffffff !important;
  font-family: sans-serif;
}
 
#login-nav-button .btn, #logout-nav-button .btn {
  height: 25px;
  width: 120px;
  border-radius: 30px;
  border: 2px solid #FFFFFF;
  background-color: var(--gcs-dark-purple);
  color: #f6f6f6;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-top: -2px;
  margin-top: 2px;
}

#login-nav-button .btn:hover, #logout-nav-button .btn:hover {
  background-color: var(--gcs-purple);
  border: 2px solid #FFFFFF;
}

/**************** NAVBAR TOGGLER BUTTON ******************/

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
  margin-right: 10px;
}

.navbar-toggler, .navbar-toggler:focus,
.navbar-toggler:active, .navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--gcs-hot-pink);
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* Create animated X to close navbar */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0)
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* Create animated hamburger lines */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100)
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

/************************* CONTACT US *********************/

#contact-us {
  background-color: var(--gcs-white);
}

.contact-text {
  line-height: 1.15;
  font-size: 2.5rem;
}

/*****************  SPINNER FOR EMAIL SIGNUP *************************/

#spinner {
  display: none;
}

#submit-text {
  display: block;
}

.submit-button {
  box-shadow: none;
  color: white;
  background: rgb(94,70,191);
  background: linear-gradient(90deg, rgba(94,70,191,1) 54%, rgba(59,47,107,1) 100%);
}

.submit-button:hover {
  background: rgb(240,203,239);
  background: linear-gradient(90deg, rgba(240,203,239,1) 54%, rgba(233,187,215,1) 100%);
}

/*****************  MODAL FOR EMAIL SIGNUP *************************/

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.close {
  color: var(--gcs-purple);
  height: 28px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.close:hover,
.close:focus {
  color: var(--gcs-hot-pink);
  text-decoration: none;
  cursor: pointer;
}

.modal-logo {
  position: relative;
  width: 150px;
  padding-top: 15px;
  padding-left: 10px;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  top: 20%;
  border: 1px solid #888;
  max-width: 400px;
  min-width: 200px;
}

.modal-content p {
  padding-left: 20px;
  padding-right: 20px;
}

/***************** FOOTER *************************/

.footer {
  background-color: var(--gcs-white) !important;
  margin-top: auto;
}

.footer-logo-img {
  max-width: 300px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social-media-footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-400);
  padding: 0;
}

.social-media-footer li {
  list-style-type: none;
}

.copyright {
  padding-top: 0px;
  padding-bottom: 10px;
  font-weight: 500;
}

/*****************  MEDIA *************************/

@media (max-width: 991.98px) {

.modal {
  padding-top: 150px; 
}

  .navbar-logo {
    max-width: 275px;
  }

  .hr1 {
    display: hidden;
  }

  .footer-logo-img {
    max-width: 275px;
  }

    #login-nav-button .btn, #logout-nav-button .btn {
    margin-top: 12px;
  }

}

@media (max-width: 767.98px) {

  .hr1 {
    display: block;
  }

  #login-nav-button .btn, #logout-nav-button .btn {
    margin-top: 12px;
  }

}

@media (max-width: 290px) {

  .navbar-logo {
    max-width: 200px;
  }

  .considering-text {
    line-height: 1.15;
    font-size: 1.75rem;
  }

  .footer-logo-img {
    max-width: 210px;
  }

}
