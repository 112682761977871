@import '/src/css/general.css';

/*************************** Winter Camp 2023 ***********************/

.winter-camp-graphic img {
  max-width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.headshot-img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  filter: drop-shadow(0.4rem 0.4rem 0.45rem rgba(0, 0, 30, 0.5));
}

.melanie {
  padding-top: 3px;
}
