@import '../scss/custom.css';
@import './css/general.css';

/********************************* HOME PAGE *************************************/

/*************************** Hero Section (PERMANENT) ***********************/

#hero-section {
  border-bottom: 5px solid var(--gcs-hot-pink);
}

.hero-section {
/*  padding-top: 20px;*/
/*  padding-top: 100px;*/
  padding-bottom: 8px;
  
}

.hero-quote {
  color: var(--gcs-dark-blue);
  font-weight: 600;
}

.hidden-hero-long {
  display: none;
}

.hidden-hero-wide {
  display: block;
}

/************************** GCS Logo Image (PERMANENT) ********************/

.hero-gcs-logo {
  max-height: 185px;
  margin-top: 10px;
}

.circle-around-logo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  float: left;
  shape-outside: circle(50%);
}

.hero-text-divider {
  width: 50%;
  height: 3px;
  background-color: var(--gcs-hot-pink);
}

/************************* Hero Buttons (PERMANENT) *********************/

/** Hot Pink to Purple **/

.hero-button-1 {
  background: rgb(182,80,178);
  background: linear-gradient(90deg, rgba(182,80,178,1) 54%, rgba(112,19,109,1) 100%);
}

.hero-button-1:hover {
  background: rgb(94,70,191);
  background: linear-gradient(90deg, rgba(94,70,191,1) 54%, rgba(59,47,107,1) 100%);
}

/** Purple to Hot Pink **/

.hero-button-2 {
  background: rgb(94,70,191);
  background: linear-gradient(90deg, rgba(94,70,191,1) 54%, rgba(59,47,107,1) 100%);
}

.hero-button-2:hover {
  background: rgb(182,80,178);
  background: linear-gradient(90deg, rgba(182,80,178,1) 54%, rgba(112,19,109,1) 100%);
}

/** Hot Pink to Turquoise **/

.hero-button-3 {
  background: rgb(182,80,178);
  background: linear-gradient(90deg, rgba(182,80,178,1) 54%, rgba(112,19,109,1) 100%);
}

.hero-button-3:hover {
  background-image: linear-gradient(to right top, #1382df, #007cce, #0076be, #006fad, #06689c);
}


/************************* Sponsor Logos (PERMANENT) *********************/

.sponsor-logo-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.live-oak-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px green;
  padding-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

/*************************** Word Cloud (PERMANENT) ***********************/

ul.cloud {
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: 2.50rem;
}

ul.cloud a {
  --size: 4;
  --color: #a33;
  color: var(--color);
  font-size: calc(var(--size) * 0.25rem + 0.5rem);
  display: block;
  padding: 0.125rem 0.25rem;
  position: relative;
  text-decoration: none;
}

ul.cloud a[data-weight="1"] { --size: 1; }
ul.cloud a[data-weight="2"] { --size: 2; }
ul.cloud a[data-weight="3"] { --size: 3; }
ul.cloud a[data-weight="4"] { --size: 4; }
ul.cloud a[data-weight="5"] { --size: 6; }
ul.cloud a[data-weight="6"] { --size: 8; }
ul.cloud a[data-weight="7"] { --size: 10; }
ul.cloud a[data-weight="8"] { --size: 13; }
ul.cloud a[data-weight="9"] { --size: 16; }

ul[data-show-value] a::after {
  content: " (" attr(data-weight) ")";
  font-size: 1rem;
}

ul.cloud li:nth-child(2n+1) a { --color: #181; }
ul.cloud li:nth-child(3n+1) a { --color: #33a; }
ul.cloud li:nth-child(4n+1) a { --color: #c38; }

ul.cloud a:focus {
  outline: 1px dashed;
}

ul.cloud a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  background: var(--color);
  transform: translate(-50%, 0);
  opacity: 0.15;
  transition: width 0.25s;
}

ul.cloud a:focus::before,
ul.cloud a:hover::before {
  width: 100%;
}

@media (prefers-reduced-motion) {
  ul.cloud * {
    transition: none !important;
  }
}

/************************************************************* MEDIA QUERIES: MIN WIDTH 1025px - Desktops ******************************************/

@media (min-width: 1025px) {

  .circle-around-logo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    float: left;
    shape-outside: circle(50%);
    shape-margin: 5px;
  }

  .hero-image {
    order: -1;
    margin: auto;
    display: block;
    text-align: center;
  }

  .hero-text-shapes {
    visibility: visible;
  }

  .section-title-size {
    font-size: var(--fs-primary-heading);
  }



.hidden-button {
  display: none;
}

}


@media (min-width: 1025px) {

  .large-devices {
    display: block;
  }

  .hero-section {
    margin-top: 20px;
  }

}


@media (min-width: 1024px) and (max-width: 1024.98px) {

  .large-devices {
    display: block;
  }

  .hero-section {
   margin-top: 110px;
/*margin-top: 20px;*/
  }

}


/*************************************************** MEDIA QUERIES: MIN WIDTH 768px to 1024.98px ******************************************/


@media (min-width: 768px) and (max-width: 1024.98px) {

  .large-devices {
    display: none;
  }

  .smaller-devices {
    display: block;
  }

  .hero-section {
/*    margin-top: 110px;*/
margin-top: 10px;
  }

  .hero-gcs-logo {
    padding-bottom: 10px;
  }

  .circle-around-logo {
    width: 200px;
    height: 200px;
    border-radius: none;
    float: none;
    shape-outside: none;
    shape-margin: none;
  }

  .hero-image {
    order: -1;
    margin: auto;
    display: block;
    text-align: center;
  }

  .hero-text-shapes {
    visibility: hidden;
  }

  .section-title-size {
    font-size: var(--fs-h1);
  }

  .seen-button {
    display: none;
  }

}

/************************************************ MEDIA QUERIES: MIN WIDTH 301px to MAX WIDTH 767.98px - Phones *************************************/

@media (min-width: 301px) and (max-width: 767.98px) {

  .large-devices {
    display: none;
  }

  .smaller-devices {
    display: block;
  }

  .hero-section {
/*    margin-top: 110px;*/
    /* margin-top: 0px; */
    margin-top: 10px;
  }

  .hero-gcs-logo {
    padding-bottom: 10px;
  }

  .hidden-hero-long {
    display: block;
  }

  .hidden-hero-wide {
    display: none;
  }

}

/*************************************************** MEDIA QUERIES: MIN WIDTH 301px to 767.98px ******************************************/

@media (min-width: 301px) and (max-width: 767.98px) {

  .circle-around-logo {
    width: 200px;
    height: 200px;
    border-radius: none;
    float: none;
    shape-outside: none;
    shape-margin: none;
  }

  .hero-image {
    order: -1;
    margin: auto;
    display: block;
    text-align: center;
  }

  .hero-text-shapes {
    visibility: hidden;
  }

/************************* Laptop Frames for Student Photos *********************/

.section-title-size {
  font-size: var(--fs-h1);
}

  .seen-button {
    display: none;
  }

}

/***************************************************** MEDIA: MAX WIDTH 300px - Small Phones *******************************************/

@media (max-width: 300px) {

  .large-devices {
    display: none;
  }

  .smaller-devices {
    display: block;
  }

/************************* Hero Section - Logo & Description *********************/

  .hero-section {
    margin-top: 40px;
  }

  .hero-gcs-logo {
    padding-bottom: 10px;
  }

  .serif-heading {
    margin-top: -15px;
  }

  .circle-around-logo {
    width: 200px;
    height: 200px;
    border-radius: none;
    float: none;
    shape-outside: none;
    shape-margin: none;
  }

  .hero-image {
    order: -1;
    margin: auto;
    display: block;
    text-align: center;
  }

  .hero-text-shapes {
    visibility: hidden;
  }

/******************* Hero Section - Laptop Frames for Student Photos *******************/

  .section-title-size {
    font-size: var(--fs-h1);
  }

  .seen-button {
    display: none;
  }

}
