@import '/src/css/general.css';

/********************** Winter Camp Banner *********************/

#banner-section {
  	margin-top: 95px;
  	border-bottom: 5px solid var(--gcs-hot-pink);
}

.snowflakes-image {
	width: 40px;
}

.banner-text a {
  	color: var(--gcs-bright-blue);
}

.banner-text a:hover {
  	color: var(--gcs-hot-pink);
}

.banner-large-devices {
	display: block;
}

.banner-smaller-devices {
  	display: none;
}

.banner-register-button {
  display: inline-block;
  height: 30px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .15rem;
  color: var(--gcs-white);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: var(--gcs-bright-blue);
  border: 3px solid var(--gcs-purple);
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
  border-radius: 30px;
  transition: all 0.15s ease-in;
  padding-top: 5px;
  font-family: sans-serif;
}

.banner-register-button:hover {
  background-color: var(--gcs-dark-purple);
  border: 3px solid #FFFFFF;
}

/************************************************************* MEDIA QUERIES: MIN WIDTH 1025px - Desktops ******************************************/

@media (min-width: 1025px) {

  .banner-large-devices {
    display: block;
  }

}

/************************************************************* MEDIA QUERIES: WIDTH 1024px - iPad Pro ******************************************/

@media (min-width: 1024px) and (max-width: 1024.98px) {

  .banner-large-devices {
    display: block;
  }

    .banner-smaller-devices {
    	display: none;
  	}

}

/*************************************************** MEDIA QUERIES: MIN WIDTH 768px to 1024.98px ******************************************/

@media (min-width: 768px) and (max-width: 1024.98px) {

	.banner-large-devices {
    	display: none;
  	}

  	.banner-smaller-devices {
    	display: block;
  	}


}

/*************************************************** MEDIA QUERIES: MIN WIDTH 301px to 767.98px ******************************************/

@media (min-width: 301px) and (max-width: 767.98px) {

	.banner-large-devices {
    	display: none;
  	}

  	.banner-smaller-devices {
    	display: block;
  	}
}

/***************************************************** MEDIA: MAX WIDTH 300px - Small Phones *******************************************/

@media (max-width: 300px) {

  .banner-large-devices {
    display: none;
  }

  .banner-smaller-devices {
    display: block;
  }

}











